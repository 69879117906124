
import InputField from '@/components/Form/InputField.vue'
import Button from '@/components/Form/Button.vue'
import { useStore } from 'vuex'
import { defineComponent, ref } from 'vue'
import { useRouter } from 'vue-router'

export default defineComponent({
  components: {
    InputField,
    Button,
  },
  setup() {
    const store = useStore()
    const router = useRouter()
    const isLoading = ref(false)
    const message = ref<string | undefined>(undefined)

    const onLogin = (event : Event) => {
      const target = event.currentTarget as HTMLFormElement
      isLoading.value = true
      message.value = undefined
      const password = target.elements.namedItem('password') as HTMLInputElement
      const email = target.elements.namedItem('email') as HTMLInputElement
      store.dispatch('login', { password: password.value, email: email.value })
        .then(() => {
          router.push({ name: 'AllProjects' })
        })
        .catch((err) => {
          message.value = err.message
        })
        .finally(() => {
          isLoading.value = false
        })
    }

    return {
      onLogin,
      isLoading,
      message,
    }
  },
})
